<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <div class="page-head-item">
        <img src="../assets/images/dt-detail-head-img.png" @click="handleToApp" />
      </div>
      <!-- 占位 -->
      <div class="page-head-container"></div>
      <div class="user-info-item">
        <div class="header-img">
          <img :src="userData.header" />
        </div>
        <div class="detail">
          <div class="nickname">{{ userData.nickname }}</div>
          <div class="info">{{ userData.sex == 1 ? '男' : '女' }}·{{ userData.birthday | calcAge() }} | {{userData.c_name}}</div>
        </div>
        <div class="btn">{{ userData.sex == 1 ? '撩他' : '撩她' }}</div>
      </div>
      <div v-if="dtDate && dtDate.id" class="dt-detail-item">
        <div class="assets-item">
          <van-swipe v-if="dtDate.type == 1" class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in dtDate.img" :key="index"><div class="img"><img :src="item.i" /></div></van-swipe-item>
          </van-swipe>
          <div v-if="dtDate.type == 2" class="img">
            <img :src="dtDate.video.i" />
            <div class="play"><img src="../assets/images/play-icon.png" /></div>
          </div>
        </div>
        <div class="title">{{ dtDate.title }}</div>
        <div class="opteron-item">
          <div class="item">
            <div class="icon"><img src="../assets/images/share-icon.png" /></div> 分享
          </div>
          <div class="flex"></div>
          <div class="item">
            <div class="icon"><img src="../assets/images/like-active-icon.png" /></div> {{ dtDate.zan_count }}
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/hi-icon.png" /></div> 打招呼
          </div>
        </div>
      </div>
      <div v-else class="empty-container">
        <div class="icon"><img src="../assets/images/empty_icon.png" /></div>
        <div class="info">动态从地球上消失了</div>
      </div>
      <div class="open-button" @click="handleToApp">偶遇圈APP内打开</div>
    </div>
    <login-component :showPopup="showPopup" v-if="showPopup" @handleHideLoginPopup="handleHideLoginPopup"></login-component>
    <div v-if="showWechatTip" class="show-tip-container">
      <wechat-browser-tips-component @handleHideWechatBrowserTips="handleHideWechatBrowserTips"></wechat-browser-tips-component>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { zpqDetail, getSetting } from '@/api/index'
import Loading from '../components/Loading.vue'
import LoginComponent from '../components/LoginComponent.vue'
import WechatBrowserTipsComponent from '../components/WechatBrowserTipsComponent.vue'

export default {
  name: 'Zpq',
  components: {
    Loading,
    LoginComponent,
    WechatBrowserTipsComponent
  },
  data () {
    return {
      id: 0,
      friendId: 0,
      dtDate: {},
      userData: {},
      pageLoading: true,
      system: '',
      upAppInfo: {},
      showPopup: false,
      showWechatTip: false,
      isWechat: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.id = to.query.id
      vm.friendId = to.query.friend_id
    })
  },
  computed: {
    ...mapState(['token'])
  },
  filters: {
    calcAge (time) {
      var nowDate = new Date()
      var birthdayDate = new Date(time * 1000)
      var age = nowDate.getFullYear() - birthdayDate.getFullYear()
      if (nowDate.getMonth() < birthdayDate.getMonth()) {
        age--
      } else if (nowDate.getMonth() == birthdayDate.getMonth() && nowDate.getDate() < birthdayDate.getDate()) {
        age--
      }
      return age + '岁'
    }
  },
  mounted() {
    this.handleGetZpqDetail()
    this.checkSystem()
    this.handleGetSetting()
  },
  methods: {
    handleHideWechatBrowserTips () {
      this.showWechatTip = false
    },
    handleToApp () {
      if (this.token) {
        if (this.isWechat) {
          this.showWechatTip = true
        } else {
          this.handleDownloadApp()
        }
      } else {
        this.handleShowLoginPopup()
      }
    },
    checkSystem () {
      const ua = navigator.userAgent
      const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
      if (ua.toLowerCase().indexOf('micromessenger') > -1) {
        this.isWechat = true
      } else {
        this.isWechat = false
      }
      if (isiOS) {
        this.system = 'ios'
      } else if (isAndroid) {
        this.system = 'android'
      } else {
        this.system = 'pc'
      }
    },
    handleGetSetting () {
      getSetting({key: 'up_app'}).then(res => {
        try {
          this.upAppInfo = JSON.parse(res.info)
        } catch (error) {
          this.upAppInfo = res.info
        }
      })
    },
    handleDownloadApp () {
      const { upAppInfo, system } = this
      if (system === 'ios') {
        window.location.href = upAppInfo.ios_down_url
      }
      if (system === 'android') {
        window.location.href = upAppInfo.android_down_url
      }
    },
    handleShowLoginPopup () {
      this.showPopup = true
    },
    handleHideLoginPopup () {
      this.showPopup = false
    },
    handleGetZpqDetail () {
      const { id, friendId } = this
      zpqDetail({id: id, friend_id: friendId}).then((res) => {
        console.log(res.info)
        var dt = res.info.dt
        if (dt && dt.id) {
          dt.img = JSON.parse(dt.img || "[]")
          dt.video = JSON.parse(dt.video || "{}")
        }
        this.dtDate = dt
        this.userData = res.info.user
        document.title = res.info.user.nickname + " 发布了新的动态"
        this.pageLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .flex {
    flex: 1;
  }
  .page-head-item {
    width: 100%;
    height: 128rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .page-head-container {
    width: 100%;
    height: 128rem;
  }
  .user-info-item {
    box-sizing: border-box;
    width: 100%;
    height: 128rem;
    padding: 0 34rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-img {
      width: 84rem;
      height: 84rem;
      border-radius: 50%;
      overflow: hidden;
    }
    .detail {
      width: 474rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .nickname {
        color: #000;
        font-size: 28rem;
        line-height: 34rem;
        font-weight: 600;
      }
      .info {
        margin-top: 8rem;
        color: #878691;
        font-size: 20rem;
        line-height: 32rem;
        font-weight: 500;
      }
    }
    .btn {
      width: 96rem;
      height: 46rem;
      line-height: 46rem;
      text-align: center;
      border: 2rem solid #000;
      border-radius: 10rem;
      color: #000;
      font-size: 24rem;
      font-weight: 500;
    }
  }
  .empty-container {
    padding-top: 248rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      width: 200rem;
      height: 200rem;
    }
    .info {
      margin-top: 20rem;
      font-size: 28rem;
      font-weight: 500;
      color: #a9a9a9;
      line-height: 34rem;
    }
  }
  .dt-detail-item {
    .assets-item {
      width: 100%;
      height: 750rem;
      /deep/ .van-swipe__indicator {
        width: 12rem;
        height: 12rem;
        background-color: #d1d1d1;
        border-radius: 6rem;
        &.van-swipe__indicator--active {
          width: 50rem;
          background-color: #fff;
        }
      }
      .img {
        width: 100%;
        height: 750rem;
        overflow: hidden;
        position: relative;
        .play {
          width: 108rem;
          height: 108rem;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -54rem;
          margin-top: -54rem;
        }
      }
    }
    .title {
      padding: 18rem 34rem 86rem;
      border-bottom: 3rem solid #eaeaea;
      font-size: 28rem;
      color: #000;
      line-height: 34rem;
    }
    .opteron-item {
      box-sizing: border-box;
      padding: 0 12rem;
      width: 100%;
      height: 104rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        padding: 0 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22rem;
        font-weight: 500;
        color: #000;
        .icon {
          width: 36rem;
          height: 36rem;
          margin-right: 8rem;
        }
      }
    }
  }
  .open-button {
    position: fixed;
    bottom: 100rem;
    left: 50%;
    margin-left: -140rem;
    width: 280rem;
    height: 80rem;
    background: #000;
    border-radius: 40rem;
    font-size: 28rem;
    font-weight: 600;
    color: #fff;
    line-height: 80rem;
    text-align: center;
  }
}
</style>
